<template>
  <v-container fluid tag="section">

        <v-dialog  v-model="alert_msg"  width="500">

           <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_retorno}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>


      
      
       </v-dialog>

<v-form ref="form">
<base-material-card
      icon="mdi-trophy"
      title="Cadastro de Eventos"
      class="px-5 py-3"
    >

    
        <v-card-text>
            <v-container>
              <v-row>
                  
                  <v-col cols="6">
                      <v-select  v-model="evento.tipo"  Required :rules="[v => !!v || 'Campo Obrigatório']"  :items="tipo_acao" filled  label="Tipo" prepend-inner-icon="mdi-bookmark"></v-select>
                  </v-col>

                  <v-col cols="6">
                      <v-select  v-model="evento.evento"  Required :rules="[v => !!v || 'Campo Obrigatório']"   :items="tipo_eventos"  v-if="evento.tipo == 'ATIVIDADE'" filled  label="Evento" prepend-inner-icon="mdi-bookmark"></v-select>
                      <v-text-field  v-model="evento.evento"   Required :rules="[v => !!v || 'Campo Obrigatório']"  filled  label="Evento"  v-if="evento.tipo == 'PREMIACÃO'" prepend-inner-icon="mdi-bookmark"></v-text-field>

                  </v-col>

                  <v-col cols="6">
                      <v-text-field  v-model="evento.pontuacao"   Required :rules="[v => !!v || 'Campo Obrigatório']"  filled  label="Pontos" prepend-inner-icon="mdi-trophy"></v-text-field>
                  </v-col>
                     <v-col cols="6">
                      <v-text-field   v-model="evento.nome_campanha"   filled  label="Nome Campanha" prepend-inner-icon="mdi-shopping"></v-text-field>
                  </v-col>

                     <v-col cols="6">
                     <v-text-field   v-model="evento.texto_campanha"  filled  label="Texto Campanha" prepend-inner-icon="mdi-marker"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                          <v-checkbox style="font-size:22px;color:red;" 
                          v-model="evento.campanha"
                                label="Campanha?"
                                color="blue"
                                hide-details>
                            </v-checkbox>
                  </v-col>

                  <v-col cols="6">
                      <v-select  v-model="evento.nome_empresa"  Required :rules="[v => !!v || 'Campo Obrigatório']"  :items="empresas" filled  label="Nome da Empresa" prepend-inner-icon="mdi-bookmark"></v-select>
                  </v-col>

                  <v-col cols="6">
                      <v-text-field  v-model="evento.telefone_empresa"  Required :rules="[v => !!v || 'Campo Obrigatório']"  v-mask="'(##) #####-####'"  filled  label="Telefone Empresa (SMS)" prepend-inner-icon="mdi-phone"></v-text-field>
                  </v-col>


            </v-row>

         <v-spacer> </v-spacer>
<br/>
    <v-btn color="success"  :loading="save_loading"  @click="salva()" >Salvar
                    <v-icon right dark>mdi-content-save</v-icon>
               </v-btn>

                   <v-btn color="danger"   @click="clear()" >Limpar
                    <v-icon right dark>mdi-broom</v-icon>
               </v-btn>



            <v-row>
    
                 
              </v-row>
              

            </v-container>
        </v-card-text>

<v-data-table
    :headers="headers"
    :items="eventos"
    :items-per-page="10"
    :loading="loading_tab"
    class="elevation-0"
  >
     <template v-slot:item.status="{ item }">
            <v-chip
              :color="getBadge(item.status)"
              dark
            >
                 {{getStatus(item.status)}}                    
            </v-chip>
       

         <v-btn style="margin-left:10px"  v-if="item.status == false"  :loading="btn_status" color="warning" small  @click="mudarStatus(index, item)">Habilitar
                    <v-icon right dark>mdi-check</v-icon>      
                </v-btn>
                <v-btn style="margin-left:10px"  v-if="item.status == true"  :loading="btn_status" color="warning" small @click="mudarStatus(index, item)">Desabilitar
                    <v-icon right dark>mdi-close</v-icon>      
                </v-btn>

                 <v-btn style="margin-left:10px"  v-if="item.status == true"   color="secondary" small @click="editarEvento(index, item)">Editar
                    <v-icon right dark>mdi-pencil</v-icon>      
                </v-btn>
     </template>

  </v-data-table>



</base-material-card>
</v-form>



  </v-container>


</template>

<script>
  import Usuario from '../../../services/usuario'
  import Promotores from '../../../services/promotores'

export default {


 mounted () {
      this.listar();
    },

  created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
  },

  data () {
      return {
        dlg: true,
        save_loading: false,
        btn_senha: false,
        btn_status: false,
        eventos:[],
        loading_tab: false,
        tipo_acao:[
          'ATIVIDADE',
          'PREMIACÃO',

        ],
        headers: [
          {
            text: 'Descrição',
            align: 'start',
            sortable: false,
            value: 'evento',
          },
          { text: 'Pontos', value: 'pontuacao' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Campanha', value: 'nome_campanha' },
          { text: 'Status', value: 'status' },
        ],

        tipo_eventos: [
        'CARNÊ DIGITAL',
        'VENDA',
        'TRANSFERÊNCIA DE TITULARIDADE',
        'PREVIDAMAIS',
        'ATUALIZAÇÃO DE EMAIL',
        'ATUALIZAÇÃO DE TELEFONE',
        'ATUALIZAÇÃO DE ENDEREÇO',
        'INDICAÇÃO VENDA',
        'INDICAÇÃO PREVIDAMAIS',
        'INDICAÇÃO FLORICULTURA',
        'INDICAÇÃO CREMAÇÃO',
        'INDICAÇÃO FUNERÁRIA',/*
        'CAIXA DE BIS',
        'VALE COMPRAS',
        'Milk Shake',
        'Squezze "Seja Sua melhor Versão"',
        'Caneca "Seja Sua melhor Versão"',
        'Big Mac (MC Donalds)',
        'Camiseta "Seja Sua melhor Versão"',
        'Sanduicheira',
        'Cinema + pipoca (casal)',
        'Massagem',
        'Cinema + pipoca (casal)',
        'Massagem',
        'Rodízio de Pizza (casal)',
        */
        ], 
         empresas: [
        'Previda'
        ], 
        evento:{
          tipo: '',
          evento: '',
          pontos: '00.000',
          campanha: '',
          nome_campanha: '',
          texto_campanha: '',
          campanha: true,
          nome_empresa: 'Previda',
          telefone_empresa: '',
          
        },
        name: 'Evento',
        senhaAtual: '',
        senhaNova: '',
        msg_retorno: '',
        alert_msg: false
      }
  },
  methods: {

    clear () {       
        this.evento = {};
        this.$refs.form.reset();
      },

   

    listar () {
       this.loading = true
       this.loading_tab = true

        Promotores.listaEventos().then(response => {
            this.eventos = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {
            this.loading = false
            this.loading_tab = false

        })
      },

  salva(){
        if(this.$refs.form.validate()){

            this.save_loading = true
            Promotores.evento(this.evento).then(response => {
            this.eventos = response.data;
            this.save_loading = true
            this.evento = {};
            if(response.status = 200){
                this.alert_msg =  true;
                this.msg_retorno =  "Salvo com sucesso!";
            }

            this.clear();
          }).catch(e => {
            this.save_loading = false
                      if(e.response.status === 403){
                        alert('Usuário não tem permissão')
                      }

          }).finally(() => {
            this.save_loading = false

          })
        }else{
          alert('Preencha todos os campos')
        }
      },
  
   editarEvento(pos, item){
            this.evento = item
      },

  mudarStatus(pos, item){
            this.btn_status = true;
            Promotores.updateStatusEvento(item.id).then(response => {
                        this.eventos.indexOf(item)
                        item.status = response.data;
                      Object.assign(this.eventos[pos], item)

        }).catch(e => {
                        this.btn_status = false;
                      if(e.response.status === 403){
                        alert('Usuário não tem permissão')
                      }

        }).finally(() => {
                      this.btn_status = false;

        })
      },
    
      setDisabled: function(status){
        return status
      },

      setEnable: function(status){
        return !status
      },

  getBadge (status) {
      switch (status) {
         case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'success'
      }
      },

  getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },  
  }


}
</script>
